import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BlikiBrief from "../BlikiBrief"

const query = graphql`
  query {
    blikis: allFile(
      filter: {
        sourceInstanceName: { eq: "bliki" }
        extension: { eq: "mdx" }
        childMdx: {
          fields: { show: { eq: true } }
          frontmatter: { type: { eq: "note" } }
        }
      }
      sort: { order: DESC, fields: childMdx___frontmatter___last_updated_on }
      limit: 5
    ) {
      edges {
        node {
          extension
          childMdx {
            frontmatter {
              title
              last_updated_on(formatString: "MMM DD, YYYY")
              tags
              type
              slug
            }
          }
        }
      }
    }
  }
`

const RecentBlikis = () => {
  const { blikis } = useStaticQuery(query)

  return (
    <section className="container lg:max-w-screen-lg mx-auto mt-10 px-4">
      <div className="flex items-baseline justify-between border-b-2 border-primary-400 mb-10">
        <h2 className="font-bold text-grey-700 py-4 -mb-0.5">Recent Notes</h2>
        <Link className="flex float-right" to="/notes">
          <h4 className="text-primary-500 text-lg font-header font-semibold">Read all →</h4>
        </Link>
      </div>
      {blikis.edges.map(({ node }, index) => {
        return (
          <BlikiBrief
            key={index}
            title={node.childMdx.frontmatter.title}
            slug={node.childMdx.frontmatter.slug}
            tags={node.childMdx.frontmatter.tags}
            type={node.childMdx.frontmatter.type}
            last_updated_on={node.childMdx.frontmatter.last_updated_on}
          />
        )
      })}
      <Link className="flex float-right" to="/notes">
        <h4 className="text-primary-500 text-lg font-header font-semibold">Read all →</h4>
      </Link>
    </section>
  )
}

export default RecentBlikis
