import React from "react"
import PageHeader from "../PageHeader"

const Hero = () => {
  return (
    <div className="bg-hero-topography-primary-10 bg-primary-700 text-grey-50 px-4 pt-24">
      <div className="container lg:max-w-screen-lg mx-auto mt-10">
        <PageHeader heading="Build like the best." />
        <p className="max-w-screen-lg text-lg sm:text-2xl sm:leading-10 font-medium mb-10 sm:mb-11">
          Learn to develop{" "}
          <strong className="text-primary-100">reliable</strong>,{" "}
          <strong className="text-primary-100">scalable</strong>,{" "}
          <strong className="text-primary-100">maintainable</strong> systems
          from first principles.
        </p>

      </div>
    </div>
  )
}

export default Hero
