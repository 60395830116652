import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "type": "note",
  "title": "Tracks",
  "last_updated_on": "2021-02-16T00:00:00.000Z",
  "slug": "tracks",
  "draft": false
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Track = makeShortcode("Track");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p"><em parentName="strong">{`Pick a path to begin your journey.`}</em></strong></p>
    <Track mdxType="Track">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/data-model"
          }}>{`Data Models`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/transactions"
          }}>{`Transactions`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/ddd"
          }}>{`Domain-driven Design`}</a></li>
        <li parentName="ul">{`EdgeDB`}</li>
        <li parentName="ul">{`Apache Avro`}</li>
        <li parentName="ul">{`Software Architecture`}</li>
      </ul>
    </Track>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      