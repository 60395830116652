import React from "react"
import Tracks from "../../../content/bliki/tracks.mdx"
import Cloud from "./Cloud"

const Explore = () => {
  return (
    <section className="container lg:max-w-screen-lg mx-auto mt-10 px-4">
      <div>
        <h2 className="font-bold text-grey-700 py-4 border-b-2 border-primary-400 -mb-0.5">
          Explore
        </h2>
        <div className="pt-8 text-lg text-grey-700">
          <em>
            This site is structured in an unusual way: Content is presented as a
            densely-linked traversable graph.
          </em>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <article className="text-lg text-grey-700 my-4">
            <Tracks />
          </article>
          <Cloud />
        </div>
      </div>
    </section>
  )
}

export default Explore
