import React from "react"

const PageHeader = ({ heading }) => {
  return (
    <h1 className="text-3xl sm:text-5xl lg:text-6xl leading-none font-extrabold tracking-tight mt-10 mb-8 sm:mt-14 sm:mb-10">
      {heading}
    </h1>
  )
}

export default PageHeader
