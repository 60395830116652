import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero from "../components/Home/Hero"
import Explore from "../components/Home/Explore"
import RecentBlikis from "../components/Home/RecentBlikis"

const Index = () => {
  return (
    <Layout>
      <SEO />
      <Hero />
      <RecentBlikis />
      <Explore />
    </Layout>
  )
}

export default Index
