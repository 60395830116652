import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { TagCloud } from "react-tagcloud"

const _ = require("lodash")

const query = graphql`
  query {
    tags: allFile(
      filter: { childMdx: { frontmatter: { type: { eq: "note" } } } }
    ) {
      group(field: childMdx___frontmatter___tags) {
        value: fieldValue
        count: totalCount
      }
    }
  }
`

const Cloud = () => {
  const { tags } = useStaticQuery(query)
  const tagCloud = tags.group

  // Filter for tags that have at least 2 references
  let filteredTags = _.filter(tagCloud, function (o) {
    return o.count > 3
  })

  // Use colors surrounding the primary color's hue
  const options = {
    luminosity: "dark",
    hue: "355",
  }

  // Convert each tag to a link
  const customRenderer = (tag, size, color) => (
    <Link
      to={`/tags/${_.kebabCase(tag.value)}`}
      key={tag.value}
      style={{
        fontSize: size,
        color: color,
        padding: "0 4px",
        display: "inline-block",
      }}
    >
      {tag.value}
    </Link>
  )

  return (
    <section className="container lg:max-w-screen-lg mx-auto my-4">
      <div>
        <p className="text-lg text-grey-700">
          <strong>
            <em>... or explore by tags.</em>
          </strong>
        </p>
      </div>
      <TagCloud
        className="my-8"
        minSize={17}
        maxSize={35}
        tags={filteredTags}
        shuffle={false}
        colorOptions={options}
        renderer={customRenderer}
      />
    </section>
  )
}

export default Cloud
